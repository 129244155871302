import {
  GET_PROPERTY_LIST,
  IDataBase,
  IGetPropertyListAction,
  ISetActiveDBAction,
  ISetActivePropertyAction,
  ISetLoaderFlagAction,
  IProperty,
  SET_ACTIVE_DB,
  SET_ACTIVE_PROPERTY,
  SET_LOADER_FLAG,
  UPDATE_SECURITY_DEPOSITS,
  SET_MOVEOUT_MONTHLYS,
  ADD_REMOVE_TENANT,
} from './types';
import { http } from '../../Utils/http';
import { Dispatch } from 'react';
import { ROOT_URL } from '../../Utils/Constants';
import {
  IMonthlyView,
  ISecurityDeposit,
  ITenant,
  ITenantPayment,
} from '../tenants/types';

export const getPropertyList =
  (databaseName: string): any =>
  async (
    dispatch: Dispatch<IGetPropertyListAction>,
    getState: any,
  ): Promise<void> => {
    let url1: string = `${ROOT_URL}/property/get.php?db_name=${databaseName}`;
    let url2: string = `${ROOT_URL}/tenants/tenants.php?db_name=${databaseName}`;
    const propertiesList = await http(url1, dispatch);
    const tenantList = await http(url2, dispatch);

    let property: IProperty;
    let modifiedPropList: IProperty[] = [];
    tenantList.tenants.forEach((tenant: ITenant) => {
      //converting LeaseExperiationDate to Date object
      tenant.LeaseExpirationDate =
        tenant.LeaseExpirationDate?.replace(/ 00:00:00/g, '') || '';
      tenant.SecurityDeposits = tenantList.securityDeposits.filter(
        (sd: ISecurityDeposit) => sd.TenantID === tenant.TenantID,
      );
      if (!property || property.PropertyID !== tenant.PropertyID) {
        property = propertiesList.find(
          (p: IProperty) => p.PropertyID === tenant.PropertyID,
        );
        property.Tenants = [];
        modifiedPropList.push(property);
      }
      const paidAmount = calculatePaidAmount(tenant, tenantList.tenantsPayment);
      property.Tenants.push({ ...tenant, PaidAmount: paidAmount });
    });

    //Adding DK Associates property, since it has no tenants it won't be added during the code above
    const dk_prop = propertiesList.find((p) => p.PropertyID === '6');
    if (dk_prop) {
      dk_prop.Tenants = [];
      modifiedPropList.push(dk_prop);
    }

    dispatch({
      type: GET_PROPERTY_LIST,
      payload: modifiedPropList,
    });

    return Promise.resolve();
  };

export const setActiveProperty = (
  property: IProperty,
): ISetActivePropertyAction => ({
  type: SET_ACTIVE_PROPERTY,
  payload: property,
});

export const setActiveDB = (db: IDataBase): ISetActiveDBAction => ({
  type: SET_ACTIVE_DB,
  payload: db,
});

export const setLoaderFlag = (flag: boolean): ISetLoaderFlagAction => ({
  type: SET_LOADER_FLAG,
  payload: flag,
});

export const setMoveoutMontlyView = (monthlyViews: IMonthlyView[]) => ({
  type: SET_MOVEOUT_MONTHLYS,
  payload: monthlyViews,
});

export const addRemoveTenant = (tenant: ITenant) => ({
  type: ADD_REMOVE_TENANT,
  payload: tenant,
});

export const updateSecurityDeposit =
  (db: string, payload: ISecurityDeposit) => async (dispatch) => {
    let url = `${ROOT_URL}/property/securityDeposit.php?db_name=${db}`;
    url = url + `&tenantId=${payload.TenantID}`;
    url = url + `&apartment=${payload.Apartment}`;
    url = url + `&checkNumber=${payload.CheckNumber || ''}`;
    url = url + `&amount=${payload.Amount}`;
    url = url + `&comment=${payload.Comment || ''}`;

    await http(url, dispatch);
    dispatch({
      type: UPDATE_SECURITY_DEPOSITS,
      payload,
    });
  };

const calculatePaidAmount = (
  tenant: ITenant,
  paymentInfo: ITenantPayment[],
): number => {
  const paidAmounts = paymentInfo.filter(
    (tp: ITenantPayment) => tp.TenantID === tenant.TenantID,
  );
  if (paidAmounts && paidAmounts.length > 0) {
    return paidAmounts.reduce((amount, item: ITenantPayment): number => {
      return amount + parseFloat(item.Amount + '');
    }, 0);
  }
  return 0;
};
