import { IDataBase } from '../modules/properties/types';

export const ROOT_URL = 'https://elite.aviezri.com/api/objects';
export const ADMINDB = 'allglute_db-admin';
export const TOKEN_KEY = 'token';
export const USER_KEY = 'user';
export const CURRENT_PAGE = 'currentPage';
export const DB_KEY = 'db';
export const LAST_DB_BACKUP = 'lastDbBackup';
export const SAVED_VIDEOS = 'savedVideos';

export const devDatabases: IDataBase[] = [
  {
    DisplayName: 'DEV-House Account',
    DatabaseName: 'allglute_dev-db-HOUSE2009',
    ID: 1,
  },
  {
    DisplayName: 'DEV-DKA',
    DatabaseName: 'allglute_dev-db-DKA2009',
    ID: 2,
  },
];
export const databases: IDataBase[] = [
  {
    DisplayName: 'House Account',
    DatabaseName: 'allglute_db-HOUSE2009',
    ID: 1,
  },
  {
    DisplayName: 'DKA',
    DatabaseName: 'allglute_db-DKA2009',
    ID: 2,
  },
  {
    DisplayName: 'Dynamic Direction',
    DatabaseName: 'allglute_db-DD',
    ID: 3,
  },
  // {
  //   DisplayName: 'Dynamic Direction Software',
  //   DatabaseName: 'allglute_db-DDS',
  //   ID: 4,
  // },
];
